:root {
  /* color definitions */
  --color-white: #fff;
  --color-white--30: rgba(255, 255, 255, 0.3);
  --color-white--50: rgba(255, 255, 255, 0.5);
  --color-white--80: rgba(255, 255, 255, 0.8);
  --color-blue-100: #f3f8fe;
  --color-blue-200: #e1eefb;
  --color-blue-300: #c0d0e4;
  --color-blue-400: #5d789a;
  --color-blue-500: #92a5bd;
  --color-blue-600: #455a72;
  --color-blue-700: #002b53;
  --color-blue-700--60: rgba(0, 43, 83, 0.6);
  --color-blue-800: #002242;
  --color-red-400: #e61914;
  --color-red-500: #b81410;
  --color-orange-100: #fef1e5;
  --color-orange-200: #fde4ce;
  --color-orange-300: #fcd7b9;
  --color-orange-400: #ff5a00;
  --color-orange-500: #cc4800;

  --color-primary: var(--color-orange-400);
  --color-secondary: var(--color-blue-700);
  --color-accent: var(--color-red-400);

  /*
   * map colors to usage
   */

  /* background */
  --bg-color-white: var(--color-white);
  --bg-color-white--50: var(--color-white--50);
  --bg-color-white--80: var(--color-white--80);
  --bg-color-blue-lighter: var(--color-blue-100);
  --bg-color-blue-light: var(--color-blue-200);
  --bg-color-blue-dark: var(--color-blue-700);
  --bg-color-red: var(--color-red-400);
  --bg-color-orange-lighter: var(--color-orange-100);
  --bg-color-orange-light: var(--color-orange-200);
  --bg-color-orange-soft: var(--color-orange-300);
  --bg-color-orange: var(--color-orange-400);
  --bg-color-overlay: var(--color-blue-700--60);

  /* background - hover states */
  --bg-color-orange-hover: var(--color-orange-500);
  --bg-color-blue-light-hover: var(--color-blue-300);
  --bg-color-red-hover: var(--color-red-500);
  --bg-color-blue-dark-hover: var(--color-blue-600);
  --bg-color-white-hover: var(--color-blue-100);

  /* border */
  --border-color-blue-lighter: var(--color-blue-200);
  --border-color-blue-light: var(--color-blue-300);
  --border-color-blue-dark: var(--color-blue-700);
  --border-color-orange: var(--color-orange-400);
  --border-color-red: var(--color-red-400);
  --border-color-white: var(--color-white);
  --border-color-white--30: var(--color-white--30);
  --focus-outline-color-default: var(--color-blue-600);

  /* text */
  --text-color-default: var(--color-blue-700);
  --text-color-white: var(--color-white);
  --text-color-blue-lighter: var(--color-blue-300);
  --text-color-blue-light: var(--color-blue-600);
  --text-color-blue-dark: var(--color-blue-700);
  --heading-text-color-default: var(--color-blue-700);
  --text-color-red: var(--color-red-400);

  /* icon */
  --icon-color-white: var(--color-white);
  --icon-color-blue-light: var(--color-blue-500);
  --icon-color-blue-dark: var(--color-blue-700);

  /* scrollbar */
  --scrollbar-track-color: var(--color-white);
  --scrollbar-thumb-color: var(--color-blue-500);

  /* stroke */
  --stroke-color-blue-lighter: var(--color-blue-200);
  --stroke-color-white: var(--color-white);
}

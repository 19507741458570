:root {
  --container-max-width-sm: 30rem; /* 480px */
  --container-max-width-md: 42rem; /* 672px */
  --container-max-width-lg: 64rem; /* 1024px */
  --container-max-width-xl: 80rem; /* 1280px */

  --container-padding-inline: min(5vw, calc(2.5vw + 3rem));
  --container-padding-fluid-lg: minmax(var(--container-padding-inline), calc(50% - var(--container-max-width-lg) / 2));
  --container-padding-fluid-xl: minmax(var(--container-padding-inline), calc(50% - var(--container-max-width-xl) / 2));
}

:root {
  --size-2: 0.125rem;
  --size-3: 0.188rem;
  --size-4: 0.25rem;
  --size-6: 0.375rem;
  --size-8: 0.5rem;
  --size-10: 0.625rem;
  --size-12: 0.75rem;
  --size-14: 0.875rem;
  --size-16: 1rem;
  --size-18: 1.125rem;
  --size-20: 1.25rem;
  --size-24: 1.5rem;
  --size-28: 1.75rem;
  --size-32: 2rem;
  --size-36: 2.25rem;
  --size-40: 2.5rem;
  --size-48: 3rem;
  --size-60: 3.75rem;
  --size-64: 4rem;
  --size-72: 4.5rem;
  --size-100: 6.5rem;
  --size-144: 9rem;
  --size-216: 13.5rem;

  /* Icon sizes
   * size of the actual svg icon
  */
  --icon-size-18: 1.125rem;
  --icon-size-24: 1.5rem;
  --icon-size-36: 2.25rem;
  --icon-size-48: 3rem;
  --icon-size-72: 4.5rem;

  /* Circle icon sizes */
  --icon-circle-size-32: 2rem;
  --icon-circle-size-36: 2.25rem;
  --icon-circle-size-48: 3rem;
  --icon-circle-size-72: 4.5rem;

  --label-size-40: 2.5rem;

  --size-fluid-1: clamp(1rem, 2vw, 1.5rem);

  --size-content-45: 45ch;

  --scrollbar-width: 8px;

  --aspect-ratio-reels: 360 / 530;

  /* Button gap uses em for scaling with font-size */
  --gap-button-default: 0.625em;

  /* Header heights from design */
  --header-height-mobile: var(--size-64); /* 128px */
  --header-height-desktop: 5.75rem; /* 92px */

  --section-block-padding-md: var(--size-60);
  --section-block-padding-lg: var(--size-72);

  --hero-block-padding-default: var(--size-24);
  --hero-block-padding-md: var(--size-48);
  --hero-block-padding-lg: var(--size-72);

  --dot-size: var(--size-8);
}

:root {
  --font-family-base: "Helvetica Now Display", sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /*
   * fluid typography setup
   * font is fluid from minValue to maxValue over course of fluid zone
  */

  --base-unit: 1rem;

  /* calc() requires one side of multiplication or division to be unitless. Keep values unitless as long as possible */

  /* values for fluid-zone MUST be expressed in --base-unit */
  --zone-start-unitless: 50; /* 800px */
  --zone-end-unitless: 75; /* 1200px */
  --zone-width-unitless: calc(
    var(--zone-end-unitless) - var(--zone-start-unitless)
  );

  /* determine how far into the fluid-zone we currently are */
  --zone-start-with-units: calc(var(--zone-start-unitless) * var(--base-unit));
  --curr-zone-width-with-units: calc(100vw - var(--zone-start-with-units));

  /*
  use scale factor from 0-1 to add difference between min and max values to min-value. You would want that scale to be unit-less, but that's not possible: We need to use the --curr-zone-width-with-units for this, which includes --base-unit. Because there is no way to remove a value's unit with calc, this scale factor will also include --base-unit
  */
  --scale-with-units: clamp(
    0 * var(--base-unit),
    var(--curr-zone-width-with-units) / var(--zone-width-unitless),
    1 * var(--base-unit)
  );

  /*
   * font-sizes in definitions
  */

  /*
  - name font-sizes after their pixel values, because those are easier to reason about
  - define font-sizes in rem because that's better for accessibility
  */

  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-32: 2rem;
  --font-size-40: 2.5rem;
  --font-size-48: 3rem;
  --font-size-72: 4.5rem;
  --font-size-96: 6rem;

  /*
  About the clamp values:

  Determining the preferred (middle) clamp-values:
  value = min-font-size + (diff-unitless * multiplication-factor)
  The multiplication-factor --scale-with-units already includes --base-unit. calc() does not allow you to multiply two values that include a unit. Therefore, the difference between the max and min font-size must be used without unit.

  example: fluid font between 32px and 40px
  - convert px to rem: 32px = 2rem; 40px = 2.5rem
  - difference between min and max font-size: 2.5rem - 2rem = 0.5rem
  - value to use with --scale-with-units: 0.5rem without its unit: 0.5
  --font-size-32-40: clamp(2rem, calc(2rem + 0.5 * var(--scale-with-units)), 2.5rem);

  This js-function creates the correct fluid font-variable definition:
  function generateFluidFontVar(minSizeInPx, maxSizeInPx) {
    const minSizeInRem = minSizeInPx / 16
    const maxSizeInRem = maxSizeInPx / 16
    const diffInRem = maxSizeInRem - minSizeInRem
    const fluidFontVar = `--font-size-${minSizeInPx}-${maxSizeInPx}: clamp(${minSizeInRem}rem, calc(${minSizeInRem}rem + ${diffInRem} * var(--scale-with-units)), ${maxSizeInRem}rem);`
    console.log(fluidFontVar)
  }

  The calc() inside clamp() isn't necessary from a css point of view, but omitting it crashes our linter
  */

  --font-size-14-18: clamp(
    0.875rem,
    calc(0.875rem + 0.25 * var(--scale-with-units)),
    1.125rem
  );

  --font-size-16-18: clamp(
    1rem,
    calc(1rem + 0.125 * var(--scale-with-units)),
    1.125rem
  );

  --font-size-18-20: clamp(
    1.125rem,
    calc(1.125rem + 0.625 * var(--scale-with-units)),
    1.25rem
  );

  --font-size-18-24: clamp(
    1.125rem,
    calc(1.125rem + 0.375 * var(--scale-with-units)),
    1.5rem
  );

  --font-size-24-28: clamp(
    1.5rem,
    calc(1.5rem + 0.25 * var(--scale-with-units)),
    1.75rem
  );

  --font-size-30-36: clamp(
    1.875rem,
    calc(1.875rem + 0.375 * var(--scale-with-units)),
    2.25rem
  );

  --font-size-32-40: clamp(
    2rem,
    calc(2rem + 0.5 * var(--scale-with-units)),
    2.5rem
  );

  --font-size-32-48: clamp(
    2rem,
    calc(2rem + 1 * var(--scale-with-units)),
    3rem
  );

  --font-size-32-64: clamp(
    2rem,
    calc(2rem + 2 * var(--scale-with-units)),
    4rem
  );

  --font-size-40-48: clamp(
    2.5rem,
    calc(2.5rem + 0.5 * var(--scale-with-units)),
    3rem
  );

  --font-size-48-78: clamp(
    3rem,
    calc(3rem + 1.875 * var(--scale-with-units)),
    4.875rem
  );

  --font-size-32-96: clamp(
    2rem,
    calc(2rem + 4 * var(--scale-with-units)),
    6rem
  );

  --font-size-48-96: clamp(
    3rem,
    calc(3rem + 3 * var(--scale-with-units)),
    6rem
  );

  /*
   * font usage
  */
  --font-size-body-default: var(--font-size-18-20);
  --font-size-small-text: var(--font-size-16);
  --font-size-tiny: var(--font-size-12);
  --font-weight-heading-default: var(--font-weight-medium);

  --line-height-body-default: 1.7;
  --line-height-text-small: 1.4;
  --line-height-heading-default: 1.1;
  --line-height-button-default: 1.2;

  --font-letterspacing-sm: 0.025em;
}
